import React, { useEffect, useState } from 'react';
import { Carousel as BootstrapCarousel } from 'react-bootstrap';
import '../static/css/Carousel.css';
import axios from "axios";

function Carousel() {
  const [images, setImages] = useState([]);
  const homePageImageUrl = `${process.env.REACT_APP_API_URL}/site_defs/homepage-image/`;

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await axios.get(homePageImageUrl, {
          mode: 'no-cors',
        });
        if(response.status === 200){
          const datas = await response.data;
          setImages(datas.map(data => data['image']));
        }else{
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    fetchImages();
  }, []);

  return (
    <div className="carousel-container" style={{ marginTop: "25vh", marginBottom: "5vh"}}>
      <BootstrapCarousel interval={3000}>
        {images.map((image, index) => (
          <BootstrapCarousel.Item key={index}>
            <div
              className="carousel-image"
              style={{ backgroundImage: `url(${image})` }}
            />
          </BootstrapCarousel.Item>
        ))}
      </BootstrapCarousel>
    </div>
  );
}

export default Carousel;
