import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Carousel } from 'react-bootstrap';
import LoadingDot from './utils/LoadingDot';
import ErrorPage from "./utils/ErrorPage";
import NotDataPage from "./utils/NotDataPage";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { FaEdit, FaTrash } from "react-icons/fa";
import { useAuth } from "./utils/provider/AuthProvider";
import './static/css/ProductCardList.css';

function ProductCardList() {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const { accessToken, isSuperuser } = useAuth();

  useEffect(() => {
    if (!categoryId) {
      setError("Category ID is missing");
      setLoading(false);
      return;
    }

    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/site_defs/product/?category_id=${categoryId}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          setProducts(response.data);
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryId, accessToken]);

  if (loading) return <LoadingDot />;
  if (error) return <ErrorPage message={error} />;
  if (!products.length) return <NotDataPage />;

  const handleImageClick = (images, description, name) => {
    setSelectedImages(images);
    setDescription(description);
    setName(name);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);
  const handleSelect = (selectedIndex) => setCurrentIndex(selectedIndex);

  const handleEdit = (id) => {
    navigate(`/create-update-product/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/site_defs/product/${id}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });
      toastr.success('Ürün başarıyla silindi!');
      setProducts(products.filter(product => product.id !== id));
    } catch (error) {
      toastr.error('Ürün silinirken bir hata oluştu.');
    }
  };

  return (
    <div className="container d-flex" style={{ margin: "25vh auto" }}>
      <div className="row">
        {products.map((product) => (
          <div className="col-12 col-md-4 mb-4" key={product.id}>
            <div className="card text-center" style={{ width: "354px" }}>
              <div className="overflow">
                <img
                  src={product.images && product.images.length > 0 ? product.images[0].image : 'default_image_url'}
                  alt=""
                  className="card-img-top"
                  onClick={() => handleImageClick(product.images, product.description, product.name)}
                  style={{ cursor: 'pointer', height: "354px", width: "354px" }}
                />
                {isSuperuser && (
                  <>
                    <button className="btn btn-danger btn-sm position-absolute top-0 start-0 m-2"
                            onClick={() => handleDelete(product.id)}>
                      <FaTrash size={15} />
                    </button>
                    <button className="btn btn-primary btn-sm position-absolute top-0 end-0 m-2"
                            onClick={() => handleEdit(product.id)}>
                      <FaEdit size={15} />
                    </button>
                  </>
                )}
              </div>
              <div className="card-body text-dark">
                <h4 className="card-title">{product.name}</h4>
              </div>
            </div>
          </div>
        ))}
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        size="lg"
        dialogClassName="custom-modal-dialog"
        contentClassName="custom-modal-content"
      >
        <Modal.Header closeButton>
          <Modal.Title>{name}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Carousel activeIndex={currentIndex} onSelect={handleSelect} style={{ height: 'auto' }}>
            {selectedImages.map((image, index) => (
              <Carousel.Item key={index}>
                <Zoom>
                  <img
                    className="d-block w-100"
                    src={image.image}
                    alt=""
                    style={{ objectFit: 'cover' }}
                  />
                </Zoom>
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 expanded-description">
            <p className="card-text">
              {description}
            </p>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ProductCardList;
