import React from 'react';
import Carousel from '../components/utils/Carousel';
import CategoryCardList from '../components/CategoryCardList';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";

function HomePage() {
  return (
    <div>
        <div>
            <Carousel/>
            <CategoryCardList />
      </div>
    </div>
  );
}

export default HomePage;
