import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || null);
  const [isSuperuser, setIsSuperuser] = useState(localStorage.getItem('isSuperuser') === 'true' || false);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }

    if (isSuperuser) {
      localStorage.setItem('isSuperuser', 'true');
    } else {
      localStorage.removeItem('isSuperuser');
    }
  }, [accessToken, isSuperuser]);

  const setToken = (token) => {
    setAccessToken(token);
  };

  const removeToken = () => {
    setAccessToken(null);
    setIsSuperuser(false);
  };

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Authorization': `Bearer ${accessToken}`,
    },
  });

  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken: setToken, removeToken, api, isSuperuser, setIsSuperuser }}>
      {children}
    </AuthContext.Provider>
  );
};