import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../utils/provider/AuthProvider';

function CreateProduct() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [productName, setProductName] = useState('');
  const [description, setDescription] = useState('');
  const [images, setImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [existingImages, setExistingImages] = useState([]);
  const { accessToken } = useAuth();
  const getCategory = `${process.env.REACT_APP_API_URL}/site_defs/category/`;

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(getCategory, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
        });
        if (response.status === 200) {
          setCategories(response.data);
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        toastr.error('Kategori bilgileri alınırken bir hata oluştu.');
      }
    };

    fetchCategories();

    if (productId) {
      const fetchProductDetails = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/site_defs/product/?product_id=${productId}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
            },
          });
          if (response.status === 200) {
            const data = response.data[0];
            setProductName(data.name || '');
            setDescription(data.description || '');
            setSelectedCategory(data.category || '');
            setExistingImages(data.images || []);
          } else {
            throw new Error(`Error: ${response.status}`);
          }
        } catch (error) {
          toastr.error('Ürün bilgileri alınırken bir hata oluştu.');
        }
      };

      fetchProductDetails();
    }
  }, [productId, accessToken]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleNameChange = (e) => setProductName(e.target.value);
  const handleDescriptionChange = (e) => setDescription(e.target.value);
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    URL.revokeObjectURL(images[index].preview);
    setImages(newImages);
  };

  const handleRemoveExistingImage = async (index) => {
    setExistingImages(prevImages => prevImages.filter((_, i) => i !== index));
    toastr.success('Resim başarıyla silindi!');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('name', productName);
    formData.append('description', description);
    formData.append('category', selectedCategory);

    if (productId) {
      // Add existing images to the form data if editing a product
      existingImages.forEach((image) => {
        formData.append('images', image.image);
      });
    } else {
      // Add new images to the form data if creating a new product
      images.forEach((image) => {
        formData.append('images', image.file);
      });
    }

    try {
      const url = productId
        ? `${process.env.REACT_APP_API_URL}/site_defs/product/${productId}/`
        : `${process.env.REACT_APP_API_URL}/site_defs/product/`;

      const method = productId ? 'put' : 'post';

      await axios({
        method,
        url,
        data: formData,
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toastr.success(productId ? 'Ürün başarıyla güncellendi!' : 'Ürün başarıyla eklendi!');
      navigate(`/site_defs/product/${selectedCategory}/`);
    } catch (error) {
      toastr.error('Ürün işlemi sırasında bir hata oluştu.');
      console.error(error);
    }
  };

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center" style={{ marginTop: '80px' }}>
      <div className="row w-100">
        <div className="col-md-12 col-lg-8 container">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="card-title mb-4">{productId ? 'Ürün Düzenle' : 'Ürün Ekle'}</h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="productName" className="form-label">Ürün Adı</label>
                  <input
                    type="text"
                    className="form-control"
                    id="productName"
                    value={productName}
                    onChange={handleNameChange}
                    placeholder="Ürün adını buraya girin"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="description" className="form-label">Açıklama</label>
                  <textarea
                    className="form-control"
                    id="description"
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Ürün açıklamasını buraya girin"
                  ></textarea>
                </div>

                <div className="mb-3">
                  <label htmlFor="category" className="form-label">Kategori Seç</label>
                  <select
                    className="form-select"
                    id="category"
                    value={selectedCategory}
                    onChange={handleCategoryChange}
                    required
                  >
                    <option value="">Kategori seçin</option>
                    {categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="mb-3">
                  <label htmlFor="imageUpload" className="form-label">Resimleri Yükle</label>
                  <input
                    type="file"
                    className="form-control"
                    id="imageUpload"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />

                  <div className="mt-3">
                    {images.length > 0 &&
                      images.map((image, index) => (
                        <div key={index} className="position-relative d-inline-block me-2">
                          <img
                            src={image.preview}
                            alt=""
                            className="img-fluid rounded"
                            style={{ objectFit: 'cover', maxHeight: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger position-absolute top-0 end-0"
                            onClick={() => handleRemoveImage(index)}
                            style={{ zIndex: 1 }}
                          >
                            &times;
                          </button>
                        </div>
                      ))}

                    {existingImages.length > 0 &&
                      existingImages.map((image, index) => (
                        <div key={index} className="position-relative d-inline-block me-2">
                          <img
                            src={image.image}
                            alt=""
                            className="img-fluid rounded"
                            style={{ objectFit: 'cover', maxHeight: '200px' }}
                          />
                          <button
                            type="button"
                            className="btn btn-danger position-absolute top-0 end-0"
                            onClick={() => handleRemoveExistingImage(index)}
                            style={{ zIndex: 1 }}
                          >
                            &times;
                          </button>
                        </div>
                      ))}
                  </div>
                </div>

                <button type="submit" className="btn btn-primary" style={{ width: '100%' }}>
                  {productId ? 'Güncelle' : 'Kaydet'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateProduct;
