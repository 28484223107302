import React from 'react';
import { FaFacebook, FaInstagram, FaTelegram, FaWhatsapp } from "react-icons/fa6";
import { BsFillHouseFill, BsPhoneFill } from "react-icons/bs";
import '../static/css/Footer.css';

function Footer() {
  return (
    <footer className="bg-dark text-light" style={{marginTop: 'auto'}}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6" style={{ paddingRight: '10px' }}>
            <p className="mb-2"><BsFillHouseFill /> Adres: Oruç Reis Mahallesi Barbaros Caddesi 601 Sokak No:7/A Esenler/İstanbul</p>
            <p className="mb-2"><BsPhoneFill /> Cep Telefon Numarası 1: 0533-464-8787</p>
            <p className="mb-2"><BsPhoneFill /> Cep Telefon Numarası 2: 0555-880-2528</p>
          </div>

          <div className="text-center">
            <div className="d-flex justify-content-center mb-3">
              <a href="https://www.instagram.com/ercan_gedik28/" className="text-light mx-2 footer-icon instagram" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={40} />
              </a>
              <a href="https://wa.me/05334648787" className="text-light mx-2 footer-icon whatsapp" target="_blank" rel="noopener noreferrer">
                <FaWhatsapp size={40} />
              </a>
              <a href="https://m.facebook.com/people/hisar-yapı-metal/100066950581677/" className="text-light mx-2 footer-icon facebook" target="_blank" rel="noopener noreferrer">
                <FaFacebook size={40} />
              </a>
              <a href="https://t.me/username" className="text-light mx-2 footer-icon telegram" target="_blank" rel="noopener noreferrer">
                <FaTelegram size={40} />
              </a>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <p className="mb-0" style={{marginTop: '5px'}}>© 2024 Hisar Yapı Metal - Tüm hakları saklıdır.</p>
            <a href="https://www.linkedin.com/in/ahmet-ta%C5%9F%C3%A7%C4%B1-2a6527192/" target='blank'
               className="text-light-emphasis mb-0" style={{marginTop: '5px'}}> By Ahmetsc
            </a>
          </div>
        </div>
      </div>

      <a href="https://wa.me/05334648787" className="whatsapp-icon" target="_blank"  rel="noopener noreferrer">
        <FaWhatsapp size={30} />
      </a>
    </footer>
  );
}

export default Footer;
