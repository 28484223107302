import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../utils/provider/AuthProvider';

function CreateHomePageImage() {
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [existingImages, setExistingImages] = useState([]);
  const { accessToken } = useAuth();
  const homePageImageUrl = `${process.env.REACT_APP_API_URL}/site_defs/homepage-image/`;

  useEffect(() => {
    const fetchHomePageImage = async () => {
      try {
        const response = await axios.get(homePageImageUrl, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          }
        });
        if (response.status === 200) {
          const imagesArray = [];
          response.data.map(data => imagesArray.push(data));
          setExistingImages(imagesArray);
        } else {
          throw new Error(`Error: ${response.status}`);
        }
      } catch (error) {
        toastr.error('Bir hata oluştu.');
      }
    };

    fetchHomePageImage();
  }, [accessToken]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files).map(file => ({
      file,
      preview: URL.createObjectURL(file),
    }));
    setImages(prevImages => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    const newImages = images.filter((_, i) => i !== index);
    URL.revokeObjectURL(images[index].preview);
    setImages(newImages);
  };

  const handleRemoveExistingImage = async (imageId, index) => {
    try {
      await axios.delete(`${homePageImageUrl}${imageId}/`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        }
      });
      setExistingImages(prevImages => prevImages.filter((_, i) => i !== index));
      toastr.success('Fotoğraf başarıyla silindi!');
    } catch (error) {
      toastr.error('Fotoğraf silinirken bir hata oluştu.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    images.forEach((image) => {
      formData.append('images', image.file);
    });

    try {
      await axios.post(homePageImageUrl, formData, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'multipart/form-data',
        }
      });

      toastr.success('İşlem başarılı!');
      navigate(`/`);
    } catch (error) {
      toastr.error('Bir hata oluştu.');
    }
  };

  useEffect(() => {
    return () => {
      images.forEach(image => URL.revokeObjectURL(image.preview));
    };
  }, [images]);

  return (
    <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center" style={{ marginTop: '80px' }}>
      <div className="row w-100">
        <div className="col-md-12 col-lg-8 container">
          <div className="card shadow-sm">
            <div className="card-body">
              <h3 className="card-title mb-4">
                {existingImages.length > 0 ? 'Anasayfa Fotoğraf Düzenle' : 'Anasayfa Fotoğraf Ekle'}
              </h3>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="imageUpload" className="form-label">Fotoğraf Yükle</label>
                  <input
                    type="file"
                    className="form-control"
                    id="imageUpload"
                    accept="image/*"
                    multiple
                    onChange={handleFileChange}
                  />
                  <div className="mt-3">
                    {images.length > 0 && images.map((image, index) => (
                      <div key={index} className="position-relative d-inline-block me-2">
                        <img
                          src={image.preview}
                          alt=""
                          className="img-fluid rounded"
                          style={{ objectFit: 'cover', maxHeight: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger position-absolute top-0 end-0"
                          onClick={() => handleRemoveImage(index)}
                          style={{ zIndex: 1 }}>
                          &times;
                        </button>
                      </div>
                    ))}

                    {existingImages.length > 0 && existingImages.map((image, index) => (
                      <div key={index} className="position-relative d-inline-block me-2">
                        <img
                          src={image.image}
                          alt=""
                          className="img-fluid rounded"
                          style={{ objectFit: 'cover', maxHeight: '200px' }}
                        />
                        <button
                          type="button"
                          className="btn btn-danger position-absolute top-0 end-0"
                          onClick={() => handleRemoveExistingImage(image.id, index)}
                          style={{ zIndex: 1 }}>
                          &times;
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ width: '100%' }}>
                  {existingImages.length > 0 ? 'Güncelle' : 'Ekle'}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateHomePageImage;